<template>
  <div class="sub-container">
    <div class="content">
      <h2 class="page-title">병원관리</h2>
      <br><br>
      <section class="section">


        <div v-if="tabMain['info']" >
          <table class="tbl-view app-detail-info">
            <colgroup>
              <col class="head" />
              <col class="data" />
              <col class="head" />
              <col class="data" />
            </colgroup>
            <tbody>
              <tr>
                <th>병원 ID</th>
                <td><span v-if="hospital.member != undefined" > {{hospital.member.email}}</span></td>
                <th>비밀번호</th>
                <td class="inp-data">
                  ********
<!--                  <button class="btn-outline pw">-->
<!--                    <span class="txt ico pw">비밀번호 재설정</span>-->
<!--                  </button>-->
                </td>
              </tr>
              <tr>
                <th>병원명</th>
                <td>{{hospital.hospitalName}}</td>
                <th>대표자</th>
                <td>{{hospital.ceoName}}</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>{{hospital.tel}}</td>
                <th>이메일</th>
                <td>{{hospital.email}}</td>
              </tr>
              <tr>
                <th>문의채널</th>
                <td colspan="3">{{hospital.contactChannel}}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="3">{{hospital.address1}} {{hospital.address2}}</td>
              </tr>
              <tr>
                <th>운영시간</th>
                <td colspan="3">
                  <span v-html="hospital.workTime1" ></span>&nbsp;
                  <span v-html="hospital.workTime2" ></span>&nbsp;
                  <span v-html="hospital.workTime3" ></span>
                </td>
              </tr>
              <tr>
                <th>진료과목</th>
                <td colspan="3">{{hospital.treatmentSubject}}</td>
              </tr>
              <tr>
                <th>의료비지원내용</th>
                <td colspan="3">
                  <div class="t-radio-group">
                    <div class="custom-checkbox" v-for="domesticMedicalCare in hospital.domesticMedicalCareList" v-bind:key="domesticMedicalCare.medicalCareId">

                      <label   >{{domesticMedicalCare.careName}}</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>재외동포지원내용</th>
                <td colspan="3">
                  <div class="t-radio-group">
                    <div class="custom-checkbox" v-for="overseaMedicalCare in hospital.overseaMedicalCareList" v-bind:key="overseaMedicalCare.medicalCareId">

                      <label   >{{overseaMedicalCare.careName}}</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>첨부서류</th>
                <td colspan="3">
                  <div class="attach-file">
                    <div class="attach-file-sub" v-for="document in hospital.documentList" v-bind:key="document.hospitalDocumentId">
                      <button  class="btn-down-file" @click="downloadItem(document.documentUrl)"><span class="txt">{{document.documentName}}</span></button>

                    </div>


                    <div class="attach-file-sub">
                      <button class="btn-down-all" @click="downloadItemAll()"><span class="txt">전체 다운로드</span></button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>보아스협력일자</th>
                <td>2020-12-11</td>
                <th>등록일자</th>
                <td>{{hospital.createdAt}}</td>
              </tr>
              <tr>
                <th>병원로고</th>
                <td>
                  <div
                          ref="hospital-logo"
                          id="hospital-logo"
                    style="width: 120px;height: 120px;background: #F0F0F0;"
                  ></div>
                </td>
                <th>병원이미지</th>
                <td>
                  <div
                          ref="hospital-image"
                          id="hospital-image"
                    style="width: 180px;height: 120px;background: #F0F0F0;"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-detail-foot flex-se">
            <button class="btn-confirm tf-inp-btn"  @click="openHospitalEdit">
              <span class="txt ico apply-w">병원정보 수정하기</span>
            </button>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>
<style></style>
<script>

export default {
  name: "HospitalDetail",
  data: () => ({
    hospital: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    domesticMedicalCareList: [],
    domesticMedicalCareValue: [],
    overseaMedicalCareList: [],
    overseaMedicalCareValue: [],
    doctorList: [],
    tabMain: {info:true, doctor:false, column:false},
    tabSub: {application:true, calculate:false, qna:false},
    hospitalId : null,
    doctorParam: { hospitalId : null },
    doctorMode: "list",
    doctor: {},
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.params.hospitalId);
    // this.$refs["member-qna"].list(null,this.hospitalId);
  },
  beforeMount() {
    this.hospitalId = this.$route.params.hospitalId;
    this.getHospital();

    // this.listHospitalDoctor();

  },

  methods: {
    async getHospital() {
      const getHospitalPromise = await this.$axios.get(
        "hospital/hospital-info"
      );
      const [response] = await Promise.all([getHospitalPromise]);
      this.hospital = response.data.data;

      for(var i=0;i<this.hospital.domesticMedicalCareList.length;i++ ) {
        this.domesticMedicalCareValue.push(this.hospital.domesticMedicalCareList[i].careName);
      }
      for(i=0;i<this.hospital.overseaMedicalCareList.length;i++ ) {
        this.overseaMedicalCareValue.push(this.hospital.overseaMedicalCareList[i].careName);
      }



      this.uploadLogo({url:this.hospital.logoImage});
      this.uploadImage({url:this.hospital.coverImage});


    },
    selectTabMain(tabName) {
      if (tabName == 'info') {
        this.tabMain = {info:true, doctor:false, column:false};
      } else if (tabName == 'doctor') {
        this.tabMain = {info:false, doctor:true, column:false};
      } else if (tabName == 'column') {
        this.tabMain = {info:false, doctor:false, column:true};
      }
    },
    selectTabSub(tabName) {
      if (tabName == 'application') {
        this.tabSub = {application:true, calculate:false, qna:false};
      } else if (tabName == 'calculate') {
        this.tabSub = {application:false, calculate:true, qna:false};
      } else if (tabName == 'qna') {
        this.tabSub = {application:false, calculate:false, qna:true};
      }
    },
    uploadLogo(param) {
      this.hospital.logoImage = param.url;
      document.getElementById("hospital-logo").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-logo").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-logo").style.backgroundSize = "180px";
    },
    uploadImage(param) {
      this.hospital.coverImage = param.url;
      document.getElementById("hospital-image").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-image").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-image").style.backgroundSize = "180px";



    },
    openHospitalEdit() {
      this.$router.push({
        path: "/hospital/edit/" + this.$route.params.hospitalId
      });
    },
    downloadItem(url) {
      window.open(url);
    },
    downloadItemAll() {
      for(var i=0;i<this.hospital.documentList.length;i++) {
        window.open(this.hospital.documentList[i].documentUrl);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.doctorList.length) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.doctorList.length;i++) {

          this.deleteArray.push(this.doctorList[i].hospitalDoctorId) ;
        }
      }
    },

    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    async updateStatus() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        status: this.status,
        contents: this.statusContents
      };
      this.$axios
        .post("hospital/status", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.statusContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    async updateMemo() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        memo: this.memoContents
      };
      this.$axios
        .post("hospital/memo", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.memoContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("hospitals", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },



  }
};
</script>
